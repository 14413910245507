import { render, staticRenderFns } from "./BusRoster.vue?vue&type=template&id=2f30af00&scoped=true&"
import script from "./BusRoster.vue?vue&type=script&lang=js&"
export * from "./BusRoster.vue?vue&type=script&lang=js&"
import style0 from "./BusRoster.vue?vue&type=style&index=0&id=2f30af00&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f30af00",
  null
  
)

export default component.exports