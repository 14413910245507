var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',{ref:"layout",on:{"refresh":_vm.refresh},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(ref){return [_c('b-breadcrumb-item',{attrs:{"text":("Reports - " + (_vm.$store.state.settings.app.current.title))}}),_c('b-breadcrumb-item',{attrs:{"text":"Bus Roster","active":""}})]}},{key:"dropdown-options",fn:function(){return [_c('b-dropdown-item',{on:{"click":_vm.refresh}},[_c('feather-icon',{attrs:{"icon":"RotateCwIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Refresh")])],1)]},proxy:true},{key:"content",fn:function(ref){
var state = ref.state;
return [_c('span',{staticClass:"d-none d-print-block p-50 font-weight-bold"},[_vm._v("Bus: "+_vm._s(_vm.selectedBus.name))]),_c('table-layout',{ref:"table-layout",attrs:{"loading":_vm.table.loading,"fields":_vm.table.fields,"items":_vm.table.items,"sorting":_vm.table.sorting,"filters":_vm.table.filters,"filters-options":{ visible: true, collapsed: false },"export-file-name":_vm.exportFileName,"export-exclude-fields":[
                        'id',
                        'selectionEnsembleId',
                        'application.id',
                        'application.instrument.id',
                        'application.teacher.username',
                        'iop', 'hasDetails'
                    ]},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('overlay-loading',{attrs:{"items":[
            { state: _vm.options.buses.loading, desc: 'Loading Buses', loaded: _vm.options.buses.items.length},
            { state: _vm.table.loading, desc: 'Loading Students', loaded: _vm.table.loaded},
            { state: state.loading, desc: 'Rendering Template'} ]}})]},proxy:true},{key:"filters",fn:function(){return [_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Bus","label-for":"bus-input"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Bus ("),_c('span',{staticClass:"text-danger"},[_vm._v("required")]),_vm._v(") ")]},proxy:true}],null,true)},[_c('v-select',{staticClass:"w-100 d-print-none",attrs:{"id":"bus-input","options":_vm.options.buses.items,"loading":_vm.options.buses.loading,"reduce":function (bus) { return bus.id; },"label":"name","select-on-tab":true,"clearable":false,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},on:{"input":_vm.getStudents},model:{value:(_vm.table.filters.bus.value),callback:function ($$v) {_vm.$set(_vm.table.filters.bus, "value", $$v)},expression:"table.filters.bus.value"}})],1)],1)],1)]},proxy:true},{key:"cell(name)",fn:function(ref){
          var data = ref.data;
return [_c('b-media',{staticClass:"d-print-none",attrs:{"vertical-align":"center","no-body":""}},[_c('b-media-aside',[_c('b-avatar',{attrs:{"variant":"primary","size":"2.5em","badge-variant":"primary","badge-offset":"-2px"}},[_c('font-awesome-icon',{attrs:{"icon":"fas fa-graduation-cap"}})],1)],1),_c('b-media-body',{staticClass:"align-self-center"},[_vm._v(" "+_vm._s(data.item.name.first)+" "+_vm._s(data.item.name.last)+" ")])],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }