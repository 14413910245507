<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs="{ }">
      <b-breadcrumb-item :text="`Reports - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Bus Roster" active/>
    </template>

    <template #dropdown-options>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{state}">
			<span class="d-none d-print-block p-50 font-weight-bold">Bus: {{ selectedBus.name }}</span>

			<table-layout ref="table-layout"
										:loading="table.loading"
										:fields="table.fields"
										:items="table.items"
										:sorting="table.sorting"
										:filters="table.filters" :filters-options="{ visible: true, collapsed: false }"
                    :export-file-name="exportFileName"
                    :export-exclude-fields="[
                        'id',
                        'selectionEnsembleId',
                        'application.id',
                        'application.instrument.id',
                        'application.teacher.username',
                        'iop', 'hasDetails'
                    ]">

				<template #overlay>
					<overlay-loading :items="[
            { state: options.buses.loading, desc: 'Loading Buses', loaded: options.buses.items.length},
            { state: table.loading, desc: 'Loading Students', loaded: table.loaded},
            { state: state.loading, desc: 'Rendering Template'},
          ]" />
        </template>

				<template #filters>
					<b-row>
						<b-col>
							<b-form-group label="Bus" label-for="bus-input">
								<template #label>
									Bus (<span class="text-danger">required</span>)
								</template>
								<v-select id="bus-input"
													v-model="table.filters.bus.value"
													:options="options.buses.items"
													:loading="options.buses.loading"
													:reduce="bus => bus.id"
													label="name"
													:select-on-tab="true"
													:clearable="false"
													:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
													class="w-100 d-print-none"
													@input="getStudents"/>
							</b-form-group>
						</b-col>
					</b-row>
				</template>


        <template #cell(name)="{data}">
          <b-media vertical-align="center" no-body class="d-print-none">
            <b-media-aside>
              <b-avatar variant="primary" size="2.5em"
                        badge-variant="primary" badge-offset="-2px">
                <font-awesome-icon icon="fas fa-graduation-cap"/>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="align-self-center">
              {{ data.item.name.first }} {{ data.item.name.last }}
            </b-media-body>
          </b-media>
        </template>

      </table-layout>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {getUser, listBuses, listStudents} from './queries/bus-roster';
import vSelect from 'vue-select';
import _ from 'lodash';
import Fuse from 'fuse.js';
import TableLayout from '@/components/TableLayout.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';
import acceptanceMixin from '@/mixins/acceptance.mixin';
import TableRowOptions from '@/components/TableRowOptions.vue';
import lodashMixin from '@/mixins/lodash.mixin';
import settingsMixin from '@/mixins/settings.mixin';

export default {
  name: 'BusRoster',
  components: {
    TableRowOptions,
    OverlayLoading,
    TableLayout,
    PageLayout,
    vSelect
  },
  mixins: [ acceptanceMixin, lodashMixin, settingsMixin ],
  data() {
    return {
      user: this.$store.state.user,
      table: {
        fields: [
          {
            key: 'selection.ensemble.name',
            label: 'Ensemble',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'instrument.name',
            label: 'Instrument',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'name.last',
            label: 'Last Name',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'name.first',
            label: 'First Name',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
        ],
				sorting: {
					by: 'id',
				},
        search: {
          type: '\'',
          value: '',
          options: {
            types: [
              { text: 'Fuzzy', value: '' },
              { text: 'Exact', value: '=' },
              { text: 'Includes', value: '\'' },
              { text: 'Starts With', value: '^' },
            ]
          },
        },
        filters: {
          bus: { key: 'busStudentsId', value: null },
        },
        items: [],
        loading: false
      },
      options: {
        buses: {
          items: [],
          loading: true
        }
      },
    }
  },
  computed: {
    selectedBus() {
      const selected = this.options.buses.items.find(item => item.id === this.table.filters.bus.value)
      if(selected) return selected
      return { name: '', slug: '' }
    },
    exportFileName() {
      return `bus-roster-${this.selectedBus.name}-${new Date().toISOString().slice(0, 10)}`
    },
  },
  async mounted() {
    await this.getCurrentUser()
    this.$refs.layout.state.loading = false
    await this.listBuses(this.table.filters.bus.value)
    if(this.table.filters.bus.value) {
      await this.getStudents(this.table.filters.bus.value)
    }
  },
  /*watch: {
    async 'table.filters.bus.value'(busId) {
      if(busId) {
        if(this.$route.query?.bus !== busId) {
          await this.$router.push({query: {bus: busId}})
        }
      }
    }
  },*/
  methods: {
    async refresh() {
      this.table.loading = true
      this.table.items = []
      this.options.buses.loading = true
      await this.listBuses(this.$route.query.bus)
      if(this.table.filters.bus.value) {
        await this.getStudents(this.table.filters.bus.value)
      }
      this.table.loading = false
    },
    async getCurrentUser() {
      /** Get Current User from Store **/
      const cognitoUser = await Auth.currentAuthenticatedUser()

      /** Get User from AppSync **/
      const response = await API.graphql(graphqlOperation(getUser, { id: cognitoUser.attributes['custom:user_id'] }));
      this.user = response.data.getUser
      this.user.groups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups']
    },
    async listBuses(busQuery) {
      this.table.loading = true
      this.options.buses.loading = true
      const input = {
        filter: {
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start, this.settingsStore.app.current.year.end
            ]
          },
        },
        limit: 500,
      }
      const response = await API.graphql(graphqlOperation(listBuses, input));
      this.options.buses.items = response.data.listBuses.items.sort((a, b) => a.name.localeCompare(b.name))

      if(busQuery) {
        this.table.filters.bus.value = busQuery
      }

      if(!this.options.buses.items.some(item => item.id === this.table.filters.bus.value)) {
        this.table.filters.bus.value = null
      }
      this.table.loading = true
      this.options.buses.loading = false
    },
    async getStudents(busId) {
      await this.listStudents(busId)
    },
    async listStudents(busId, nextToken, pagedItems) {
      this.table.loading = true
      const items = pagedItems || []
      const input = {
        filter: {
          busStudentsId: { eq: busId },
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start,
              this.settingsStore.app.current.year.end
            ]
          },
        },
        limit: 500,
        nextToken: nextToken
      }

      const response = await API.graphql(graphqlOperation(listStudents, input));
      items.push(...response.data.listStudents.items);

      if(response.data.listStudents.nextToken) {
        await this.listStudents(busId, response.data.listStudents.nextToken, items)
      }
      else {
        this.table.items = items.map(item => {
          const application = item.applications.items.find(app => app?.selection?.accepted && app?.selection?.ensemble?.code !== 'A') || null;
          return {
            name: item.name,
            busStudentsId: item.busStudentsId,
            instrument: application ? application.instrument : null,
            selection: application ? application.selection : null
          };
        }).sort((a, b) =>
            a?.selection?.ensemble?.name.localeCompare(b?.selection?.ensemble?.name)
            || a?.instrument?.name.localeCompare(b?.instrument?.name)
            || a?.name?.last.localeCompare(b?.name?.last)
            || a?.name?.first.localeCompare(b?.name?.first));

        this.table.loading = false
      }
    },




    print() {
        this.$nextTick(() => {
            window.print()
        })
    }
  }
}
</script>

<style scoped>
  @media print {
    table > tbody > tr > td {
        font-size: 12px!important;
    }
    .print-title
    {
      display: block !important;
    }
    .badge {
        border-width: 0;
        padding: 0;
        background: transparent;
        color: inherit;
        font-weight: 400;
    }
}
</style>
